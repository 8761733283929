<template>
    <div>
        <vue-title title="Уведомления | KEL.KZ"></vue-title>
        <Header :routes="headerRoutes.defaultsoutes" v-if="!reRenderComponent"/>
        <div class="container py-4">
            <div class="long-title text-center mb-3">
                <h1 class="page-title">Уведомления</h1>
            </div>
            <div class="row">
                <div class="col-12">
                    <div v-if="!notifications">
                        <DxLoadIndicator
                            id="medium-indicator"
                            :height="20"
                            :width="20"
                        />
                        Загрузка
                    </div>
                    <div v-if="notifications && notifications.length > 0">
                        <div v-if="errorMessage" class="alert alert-danger mb-3" role="alert">
                            <strong>Внимание!</strong> {{ errorMessage }}
                        </div>
                        <div class="notification mb-3" :class="{'notification-subscribe': notification.subscribe, 'notification-unsubscribe': !notification.subscribe}" v-for="notification in notifications" :key="notification.noticeId">
                            <DxCheckBox
                                :id="'notificationId-'+notification.noticeId"
                                :value="notification.subscribe"
                                :text="notification.caption"
                                cssClass="dx-checkbox-container-custom"
                                @value-changed="notificationSubscribeUnsubscribe"
                                :disabled="isNotificationEdit"
                            />
                            <div class="notification-note mt-2">
                                {{ notification.note ? notification.note : 'Нет описания' }}
                            </div>
                        </div>
                    </div>
                    <div v-if="notifications && notifications.length == 0">
                        <h5 class="my-5">На данный момент уведомлений нет.</h5>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
    import headerRoutes from './header-routes';
    import {mapGetters} from 'vuex';
    import {Header, Footer} from '../../shared/components';
    import localStore from './store';
    import {DxLoadIndicator} from 'devextreme-vue/load-indicator';
    import { DxCheckBox } from 'devextreme-vue/check-box';
    import {httpClient} from '../../shared/services';

    export default {
        name: "ProfileNotifications",
        components: {
            Header,
            Footer,
            DxLoadIndicator,
            DxCheckBox
        },
        data() {
            return {
                headerRoutes,
                reRenderComponent: false,
                notifications: null,
                errorMessage: '',
                isNotificationEdit: false
            }
        },
        mounted() {
            this.getNotifications();
        },
        methods: {
            getNotifications() {
                this.isNotificationEdit = false;
                this.$store.dispatch('loading', true).then(() => {
                    localStore.dispatch('getNotifications', {tokenId: this.Agent.tokenId}).then(() => {
                        this.notifications = localStore.state.notifications;

                        this.$store.dispatch('loading', false);
                    });
                });
            },
            notificationSubscribeUnsubscribe(e) {
                let notificationId = e.element.id.replace('notificationId-', '');

                switch (e.value) {
                    case true:
                        this.isNotificationEdit = true;

                        httpClient.post(`v2/Notifications/${notificationId}/Subscribe?tokenId=${this.Agent.tokenId}`).then(() => {
                            this.getNotifications();
                        }).catch(this.handleError);
                        break;
                    case false:
                        this.isNotificationEdit = true;

                        httpClient.post(`v2/Notifications/${notificationId}/Unsubscribe?tokenId=${this.Agent.tokenId}`).then(() => {
                            this.getNotifications();
                        }).catch(this.handleError);
                        break;
                }
            },
            handleError: function (error) {
                if (error.response.status === 400) {
                    if(error.response.data) {
                        let errorMessage = error.response.data;

                        this.errorMessage = errorMessage;
                    } else {
                        this.errorMessage = "Ошибка при сохранении данных.";
                    }
                } else {
                    this.errorMessage = "Ошибка при сохранении данных.";
                }

                this.isNotificationEdit = false;

                console.log(error);
                this.getNotifications();
            },
        },
        computed: {
            ...mapGetters([
                'isLoading',
                'Agent'
            ])
        },
    }
</script>

<style lang="scss">
    .notification {
        background: #fff;
        border: 1px solid #ddd;
        border-radius: 4px;
        padding: 7px;

        .dx-checkbox {
            border: 0px;
            background: #fff;
            border-radius: 0px;
            font-size: 12px;
            font-weight: 600;
            text-transform: uppercase;
            display: block;
        }

        .dx-checkbox:hover .dx-checkbox-has-text .dx-checkbox-icon {
            border: 1px solid #1e3c69;
        }

        .dx-checkbox-has-text .dx-checkbox-text {
            font-size: 12px;
            font-weight: 600;
            color: #999;
        }

        .dx-checkbox-has-text .dx-checkbox-icon {
            background-color: #dee2e6;
        }

        .dx-checkbox-checked .dx-checkbox-text {
            color: #212529;
        }

        .dx-checkbox-checked .dx-checkbox-icon {
            font: 16 px/16 px DXIcons;
            background-color: #2c5799;
            color: #fff;
            text-align: center;
        }

        .notification-note {
            font-size: 12px;
        }

        //&.notification-unsubscribe {
        //    .notification-note {
        //        color: #999;
        //    }
        //}
    }
</style>
